<template>
    <div class="lazy-img" v-lazy-container="{ selector: '.avatar-image',error: 'https://cdn.001ppt.cn/h5/static/lazy/avatar.png', loading: '/static/lazy/avatar.png'}">
        <img :data-src="src" :class="[round && 'round',border && 'border']" class="avatar-image" :width="width" :height="width" :style="{height: width+'px'}"/>

        <!--<template v-if="vipIdentify == 1">-->
            <!--<img class="vip" v-if="vipRightId != 4" src="https://cdn.001ppt.cn/assets/img/member/vip-icon01.svg" alt="" width="15">-->
            <img class="vip" v-if="getVipIcon(vipRightId)" :src="getVipIcon(vipRightId)" alt="" width="15">
        <!--</template>-->
    </div>
</template>

<script>
    export default {
        name: "avatar",
        props: {
            src: {
                type: String,
                default: ''
            },
            round: {
                type: Boolean,
                default: true
            },
            border: {
                type: Boolean,
                default: true
            },
            vipIdentify: {
                type: Number,
                default: 0
            },
            vipRightId: {
                type: Number,
                default: 1
            },
            width: {
                type: Number,
                default: null
            }

        }
    }
</script>

<style scoped lang="less">
    .lazy-img{
        position: relative;
        .vip{
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .avatar-image{
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            object-fit: cover;
            &.round{
                border-radius: 100%;
            }
            &.border{
                border: 1px solid #f2f2f2;
            }
        }
    }
</style>
