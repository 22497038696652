<template>
  <div class="invite-code-dialog" :class="{visible: visible}">
    <div class="invite-code-dialog-shade" @click="toggleDialog"></div>
    <img :src="`/api/applet/v2/inviteCodeSharePost.jpg?Token=${token}`" class="invite-code-post" alt="">
    <div class="buttons">
      <div class="button" @click="copyInviteCode">复制邀请码</div>
      <div class="button" @click="savePost">保存海报</div>
    </div>
  </div>
</template>

<script>
import Model from "../model/model";
import {copyToClipBoard} from "../../config/util";
import {userV2Api} from "../../api/v2/userV2Api";
import {taskV2Api} from "../../api/v2/taskV2Api";

export default {
  components: {Model},


  data() {
    return {
      hasInit: false,
      inviteCode: '',
      token: '',
      visible: false,
      taskInfo: {
        profitYanzhi: 0,
        desc: ''
      }
    }
  },

  created() {
    this.token = localStorage.getItem('token')
  },


  methods: {

    savePost() {
      // const picUrl = '/api/applet/v2/inviteCodeSharePost.jpg?Token='+this.token
      // const a = document.createElement("a"); //创建a 标签
      // a.href = picUrl; // 把图片路径赋到a标签的href上
      // a.download = picUrl.replace(/(.*\/)*([^.]+.*)/gi, "$2").split("?")[0]; // 图片文件名
      // // 创建鼠标事件并初始化
      // const e = new MouseEvent("click", (true, false, window));
      // // 执行保存到本地
      // a.dispatchEvent(e);
      // // this.$toast.info('长按图片即可保存哦~')
      this.$toast.info('长按海报即可保存哦~')
    },


    toggleDialog() {

      if (!this.hasInit) {
        this.loadInviteCode()
        this.loadTaskInfo()
        this.hasInit = true
      }
      this.visible = !this.visible
    },


    async loadInviteCode() {
      this.inviteCode = await userV2Api.findMyInviteCode();
    },


    async loadTaskInfo() {
      const tasks = await taskV2Api.findAll()
      this.taskInfo = tasks.find(t => t.id === 62)
    },


    copyInviteCode() {
      copyToClipBoard(this.inviteCode)
      this.$toast.success('复制成功')
    }
  }

}
</script>

<style scoped lang="less">
/*.invite_code {*/
/*  color: #ff0000;*/
/*  padding-top: 24px;*/
/*  font-size: 24px;*/
/*}*/

.invite-code-dialog {
  position: fixed;
  height: 0;
  width: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
}

.invite-code-dialog .invite-code-dialog-shade {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #151D36;
  opacity: .7;
  z-index: -1;
  display: none;
}

.invite-code-dialog.visible {
  height: 100vh;
  width: 100vw;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1001;
}

.invite-code-dialog.visible .invite-code-dialog-shade {
  display: block;
}

.invite-code-post {
  width: 85vw;
  height: calc(85vw / 3 * 4);
  transform: translateY(-10px);
  transition: transform .1s ease-in-out;
}

.invite-code-dialog.visible .invite-code-post{
  transform: translateY(0);
}

.invite-code-dialog .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  width: 85vw;
  transform: translateY(-10px);
  transition: transform .1s ease-in-out;
}

.invite-code-dialog.visible .buttons{
  transform: translateY(0);
}

.invite-code-dialog .button {
  height: 38px;
  width: 140px;
  background: #fff;
  color: #151D36;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 16px;
}
</style>
