<template>
  <div class="center" style="overflow-y: scroll">
    <v-header>
      <div slot="back" class="back" @click="$router.push('/message')">
        <i class="iconfont icon-message"></i>
      </div>
    </v-header>
    <home-header ref="homeHeader" :hide-header="true"/>
    <div class="info-page">
      <div class="center_top">
        <div class="center_top_top">
          <div class="center_top_top_header">
            <div class="avatar-cell">
              <div @click="$router.push('/editInfo')">
                <avatar class="avatar" :src="userInfo.photoResourceUrl"></avatar>
              </div>
              <template v-if="userVip.vipIdentify == 1">
                <img v-if="getVipIcon(userVip.vipRightId)" :src="getVipIcon(userVip.vipRightId)"
                     class="vip-img"/>
              </template>
            </div>
          </div>
          <div class="center_top_top_detail">
            <div class="user-group">
              <span
                class="username" v-if="userInfo && userInfo.username">{{
                  userInfo.username.length > 8 ? userInfo.username.slice(0, 7) + '...' : userInfo.username
                }}</span>
              <!--              <img class="level" :src="`https://cdn.001ppt.cn/h5/static/level/level${userInfo.userLevel}.svg`">-->
              <user-level style="margin-left: 8px;transform: translateY(1px)" :level="userInfo.userLevel"/>
            </div>

            <!--            <div class="desc">-->
            <!--              <span>{{clipCity(userInfo.city)}}</span>-->
            <!--              <span class="line">|</span>-->
            <!--              <span>{{userInfo.profession || '未知'}}</span>-->
            <!--            </div>-->

            <div style="display: flex">
              <router-link to="/wallet" class="wallet">
                严值余额{{ yanzhiFilter(wallet.totalRemainValue, 999) }}
                <template v-if="wallet.remainProfitValue">
                  <span class="wallet-split"></span>
                  可提现¥{{ moneyFilter(wallet.remainProfitValue / 10, null, 99) }}
                </template>
                <van-icon name="arrow" style="display: inline-block;margin-left: 8px;"/>
              </router-link>
            </div>

          </div>
          <div class="center_top_top_edit" @click="routeInformation">
            主页
            <van-icon name="arrow" size="18"></van-icon>
          </div>
        </div>
        <div class="center_top_top center_top_top_nav">
<!--          <div class="center_top_top_nav_item" @click="$router.push('/publish')">-->
<!--            <img src="@/assets/svg/publish.svg" alt="">-->
<!--            我的发布-->
<!--          </div>-->
          <div class="center_top_top_nav_item" @click="$router.push('/member?id=4')">
            <div class="user-statistic-num">{{fansNumFilter(diffDays(userInfo.createTime),10000)}}</div>
            <div class="user-statistic-text">
              <!--              <img src="@/assets/svg/time.svg" alt="">-->
              陪伴天数 <van-icon name="arrow"/>
            </div>
          </div>
          <div class="center_top_top_nav_item" @click="$router.push('/browseRecord')">
            <div class="user-statistic-num">{{fansNumFilter(userInfo.browseAmount || 0,10000)}}</div>
            <div class="user-statistic-text">
              <!--              <img src="@/assets/svg/time.svg" alt="">-->
              我的足迹 <van-icon name="arrow"/>
            </div>
          </div>
          <div class="center_top_top_nav_item" @click="$router.push('/collection')">
            <div class="user-statistic-num">{{fansNumFilter(userInfo.collectAmount|| 0,10000)}}</div>
            <div class="user-statistic-text">
<!--              <img src="@/assets/svg/star.svg" alt="">-->
              我的收藏 <van-icon name="arrow"/>
            </div>
          </div>
          <div class="center_top_top_nav_item" @click="$router.push('/unlock')">
            <div class="user-statistic-num">{{fansNumFilter(userInfo.payAmount || 0,10000)}}</div>
            <div class="user-statistic-text">
              <!--              <img src="@/assets/svg/unlock.svg" alt="">-->
              我的解锁 <van-icon name="arrow"/>
            </div>
          </div>
        </div>

        <div class="center_top_bottom">
          <img class="crown"
               :src="getVipIcon(userVip.vipRightId) || 'https://cdn.001ppt.cn/h5/assets/images/crown-big.png'" alt="">
          <!--          <img class="crown" v-if="userVip.vipRightId === '4'"-->
          <!--               src="https://cdn.001ppt.cn/assets/img/member/vip-icon02.svg"/>-->
          <!--          <img class="crown" v-else-if="userVip.vipRightId === '2'"-->
          <!--               src="https://cdn.001ppt.cn/assets/img/member/vip-icon01.svg"/>-->
          <!--          <img class="crown" v-else src="https://cdn.001ppt.cn/h5/assets/images/crown-big.png"/>-->

          <div class="joinBox">
            <div class="joinTxt">
              <template v-if="userVip.vipRightId === '3' && userVip.lastExpireVipRightId">
                <template v-if="userVip.lastExpireVipRightId === '4'">
                  超级VIP会员·已过期{{ userVip.lastExpireVipDays }}天
                </template>
                <template v-else-if="userVip.lastExpireVipRightId === '5'">
                  俱乐部会员·已过期{{ userVip.lastExpireVipDays }}天
                </template>
                <template v-else-if="userVip.lastExpireVipRightId === '1'">
                  试用会员·已过期{{ userVip.lastExpireVipDays }}天
                </template>
              </template>
              <template v-else-if="!notExpire">加入会员，升职快人1步</template>
              <template v-else-if="isInfinityExpire(userVip.expireTime)">{{userVip.vipRightName}} · 有效期已买断</template>
              <template v-else>
                <div v-if="userVip.vipRightId === '1'">试用会员有效期至：</div>
                <div v-else-if="userVip.vipRightId === '2'">入门会员有效期至：</div>
                <div v-else-if="userVip.vipRightId === '4'">超级VIP会员有效期至：</div>
                <div v-else-if="userVip.vipRightId === '5'">俱乐部会员有效期至：</div>
                <div>{{ userVip.expireTime }}</div>
              </template>
            </div>
          </div>
          <div class="button" @click="goMember">
            <span>
              <template v-if="isInfinityExpire(userVip.expireTime)">会员中心</template>
            <template v-else-if="userVip.vipRightId!=='3'">去续费</template>
            <template v-else-if="userVip.lastExpireVipRightId">去续费</template>
            <template v-else>去加入</template>
            </span>
          </div>
        </div>
      </div>

      <div class="panel">
        <div class="panel-item" @click="$refs.recommendDialog.toggleDialog()">
          <img class="panel-item-img" src="https://cdn.001ppt.cn/h5/static/image/mine-invite-user.png" alt="">
          <div class="panel-item-main">
            <div class="panel-item-name">邀请好友</div>
            <div class="panel-item-award">+5元（可抵扣）</div>
          </div>
        </div>
        <div class="panel-item" @click="$refs.inviteCodeDialog.toggleDialog()">
          <img class="panel-item-img" src="https://cdn.001ppt.cn/h5/static/image/mine-invite-vip.png" alt="">
          <div class="panel-item-main">
            <div class="panel-item-name">邀请开通VIP</div>
            <div class="panel-item-award">+50元 (可提现)</div>
          </div>
        </div>
      </div>

      <div class="center_cell">
        <div class="center_cell_item" @click="$router.push('/wallet')">
          <div class="center_cell_item_title">
            <div>我的钱包</div>
            <img class="center_cell_item_icon" style="height: 20px;width: 20px;" src="@/assets/svg/coin.svg" alt="">
          </div>
          <div>
            <van-icon name="arrow"/>
          </div>
        </div>
        <div class="center_cell_item" @click="$router.push('/bindAccount')">
          <div class="center_cell_item_title">
            <div>账号安全</div>
          </div>
          <div>
            <van-icon name="arrow"/>
          </div>
        </div>


        <div class="center_cell_item center_cell_banner"
             onclick="window.location.href='https://www.001ppt.com/urlScheme?remark=微信群&path=pages/wxGroup/index'">
          <div class="center_cell_banner_title">
            <img style="height: 24px;width: 24px;" src="@/assets/svg/security.png" alt="">
            <div>社群矩阵</div>
          </div>
          <div class="center_cell_banner_desc">
            <div>点击加入</div>
          </div>
        </div>

        <div class="center_cell_item" @click="$refs.gzhDialog.show()">
          <div class="center_cell_item_title">
            <div>专属客服</div>
          </div>
          <div>
            <van-icon name="arrow"/>
          </div>
        </div>

        <div class="center_cell_item" @click="$router.push('/userPraise')">
          <div class="center_cell_item_title">
            <div>真实评价</div>
            <img class="center_cell_item_icon" style="height: 16px;width: 16px;" src="@/assets/svg/heart.svg" alt="">
          </div>
          <div>
            <van-icon name="arrow"/>
          </div>
        </div>

        <div class="center_cell_item" @click="$router.push('/about')">
          <div class="center_cell_item_title">
            <div>关于</div>
          </div>
          <div>
            <van-icon name="arrow"/>
          </div>
        </div>

      </div>

      <div class="logout" @click="logout">
        <div><span>退出</span></div>
      </div>

      <div class="go-pc" @click="copy('https://www.001ppt.com')">
        <van-icon name="warning-o" style="margin-right: 4px"/> 电脑下载更更更高效 (001ppt.com)<van-icon style="margin-left: 4px" name="orders-o"/>
      </div>
    </div>

    <gzh-dialog ref="gzhDialog" :type="2"/>
    <recommend-dialog ref="recommendDialog" @share="$refs.homeHeader.showShare()"/>
    <invite-code-dialog ref="inviteCodeDialog"/>
  </div>

</template>

<script>
import {user} from "@/api";
import {mapGetters, mapMutations} from 'vuex'
import avatar from '@/components/avatar'
import GzhDialog from '@/components/GzhDialog'
import UserLevel from "../../components/userLevel";
import RecommendDialog from "../../components/biz/RecommendDialog";
import InviteCodeDialog from "../../components/biz/inviteCodeDialog";
import HomeHeader from "../home/components/homeHeader";
import {copyToClipBoard} from "../../config/util";

export default {
  computed: {...mapGetters(['userVip', 'userInfo', 'wallet'])},
  components: {HomeHeader, InviteCodeDialog, RecommendDialog, UserLevel, avatar, GzhDialog},

  data() {
    return {
      notExpire: false
    }
  },

  mounted(){
    if(this.hasLogin){
      if(this.$route.query.inviteCodeVisible){
        this.$refs.inviteCodeDialog.toggleDialog()
      }
    }
  },

  async created() {
    await this.$store.dispatch('getMemberInfo');
    await this.$store.dispatch('getWallet')
    this.getUserInfo();
    const vipInfo = this.userVip;
    let notExpire = false;
    if (vipInfo.vipRightId !== 3 && vipInfo.expireTime && vipInfo.expireTime.length) {
      const expireTime = new Date(vipInfo.expireTime.replace(/-/g, '/'));
      if (expireTime.getTime() > Date.now()) {
        notExpire = true;
      }
    }
    this.notExpire = notExpire;
  },

  methods: {
    ...mapMutations(['SET_USERINFO']),

    copy(str){
      copyToClipBoard(str)
      console.log(Object.keys(this.$toast))
      this.$toast.success('复制成功~')
    },

    routeInformation() {
      this.$router.push({
        path: '/information'
      })
    },

    //获取用户信息
    async getUserInfo() {
      const res = await user.info();
      res.data.tags = res.data.tags ? res.data.tags.split(',') : [];
      this.user = res.data;
      this.SET_USERINFO(res.data);
    },

    async logout() {
      const res = await user.logout();
      if (res.code != 1) {
        return this.$toast.error('退出失败');
      }
      localStorage.clear();
      window.location.href = '/home';
    },


    goMember() {
      const ticket = this.$store.getters["ticket/currTicket"]
      console.log(ticket)
      if (ticket) {
        this.$router.push('/member?id=' + ticket.vipRightId)
      } else {
        this.$router.push('/member')
      }
    }

  }
}
</script>

<style scoped lang="less">
/* pages/personal/index/index.wxss */
.center {
  background-color: #f7f7f7;
  overflow: hidden;
}

.info-page {
  margin-bottom: 70px;
  margin-top: var(--header-height);
  overflow-y: auto;
}


.center_top {
  /*padding: 0 27px;*/
}

.center_top_top {
  padding: 27px 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
}

.center_top_top_header {
  width: 52px;
  height: 52px;
  overflow: hidden;
}

.center_top_top_header img {
  width: 100%;
  height: 100%;
}

.avatar-cell {
  width: 52px;
  height: 52px;
  position: relative;

  .avatar {
    width: 52px;
    height: 52px;
    border-radius: 100%;
  }

  .vip-img {
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 15px;
    height: 15px;
    border-radius: 100%;
  }
}

.center_top_top_detail {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 15px;
}

.center_top_top_detail .user-group {
  display: flex;
  align-items: center;

}

.center_top_top_detail .user-group .username {
  font-size: 20px;
  font-weight: bold;
  max-width: calc(100vw - 54px - 52px - 80px - 60px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.center_top_top_detail .user-group .level {
  width: 34px;
  height: 17px;
  margin-left: 10px;
}

.center_top_top_detail .desc {
  display: flex;
  align-items: center;
}

.center_top_top_detail .desc span {
  font-size: 12px;
  color: #999;
}

.center_top_top_detail .desc .line {
  margin: 0 7px;
}

.center_top_top_edit {
  display: flex;
  align-items: center;
}

.center_top_top_edit .editIcon {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
}

/* 加入会员块 */
.center_top_bottom {
  background-color: var(--text-color);
  padding: 20px;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  display: flex;
  align-items: center;
  margin: 16px 27px 0 27px;
}

.center_top_bottom .crown {
  width: 40px;
  height: 40px;
}

.center_top_bottom .joinBox {
  flex: 1;
}

.center_top_bottom .joinTxt {
  font-size: 12px;
  color: #fff;
  padding-left: 10px;

  div {
    font-size: 12px;
    color: #fff;
  }
}

.center_top_bottom .button {
  background-color: #fff;
  font-size: 14px;
  color: rgba(80, 80, 80, 1);
  padding: 4px 9px;
  border-radius: 15px;
}

.logout {
  margin-top: 10px;
  background-color: #fff;
  text-align: center;
  padding: 10px;
}

.logout span {
  font-size: 14px;
  font-weight: bold;
}

.center_top_top_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  font-size: 12px;
}

.center_top_top_nav_item {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .user-statistic-text{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-statistic-num{
    font-size: 22px;
    font-weight: bolder;
  }

  img {
    height: 12px;
    width: 12px;
    margin-right: 4px;
  }
}

.center_cell {
  padding-top: 16px;
  background: #fff;
}

.center_cell_item {
  padding: 0 27px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.center_cell_item_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bolder;
}

.center_cell_banner {
  background: #151d36;
  height: 56px;
  line-height: 56px;
}

.center_cell_banner_title {
  color: #fee09f;
  font-size: 14px;
  font-weight: bolder;
  display: flex;
  align-items: center;
}

.center_cell_banner_desc {
  color: #fff;
  font-size: 12px;
  background: #3f465a;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 100px;
}


.panel {
  display: flex;
  align-items: center;
  background: #fff;
  margin-bottom: 9px;
}

.panel-item {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78px;
}

.panel-item-main {
  padding-left: 9px;
}

.panel-item-img {
  height: 40px;
  width: 40px;
}

.panel-item-name {
  font-size: 14px;
  font-weight: bolder;
  padding-bottom: 4px;
}

.panel-item-award {
  opacity: 0.5;
  color: rgba(21, 29, 54, 1);
  font-size: 12px;
}


.wallet {
  font-size: 8px;
  //width: 200px;
  height: 20px;
  line-height: 20px;
  //display: inline-block;
  display: flex;
  flex-wrap: nowrap;
  transform-origin: 0 0;
  background: rgba(248, 208, 125, 1);
  color: #fff;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  padding: 0 10px;
  margin-top: 4px;
}

.wallet-split {
  display: inline-block;
  height: 10px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 4px;
}

.back .iconfont {
  transform: rotate(0);
}

.go-pc{
  text-align: center;
  color: #999;
  font-size: 12px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
