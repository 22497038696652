<template>
  <div @click.stop="$router.push('/userLevel')" class="user-level" :style="style">LV.{{level}}</div>
</template>


------------------ script ------------------
<script>
export default {
  props: ['level','scale'],
  computed:{
    style(){
      let styles = ['color: #ffe19f;'];
      if(this.scale){
        styles.push(`transform:scale(${this.scale})`)
      }
      return styles.join(';');
    }
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.user-level{
  font-size: 12px;
  background-color: #151D36;
  color: #ffe19f;
  padding: 2px 8px;
  border-radius: 100px;
  display: inline-block;;
  &:visited,&:active{
    color: #ffe19f;
  }
}

</style>
